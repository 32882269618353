import "./Footer.css";

import phone from "../Assets/Icons/phone-solid-white.svg";
import address from "../Assets/Icons/location-dot-solid.svg";
import mail from "../Assets/Icons/envelope-solid-white.svg";
import youtube from "../Assets/Icons/youtube.svg";
import facebook from "../Assets/Icons/facebook-f.svg";
import instagram from "../Assets/Icons/instagram.svg";


export default function Footer(){
    return(
        <div className="Footer">
                    

          <div className="grid-container">
                <div className="header"><h2>Contacts</h2></div>
                <div className="header"><h2>Links</h2></div>

                <div className="cell">
                  <div className="elementicon"><img src={address} alt="" className='icons-address'/></div>
                  <h4 className="elementtext">118 Motilal Gupta Road, Kolkata-700008, West Bengal Hard Metal Stoppage, Opposite to Jalavayu Residency, Behala, Purba Barisha</h4>
              </div>

                <div className="cell">
                  <h4 className="elementicon"><a href="https://www.instagram.com/techspro_global_institute?igsh=dWJhMzdlanpuZzZm" target="-blank" >
                    <img src={instagram} alt="" className='icons-address'/>
                  </a></h4>
              </div>


                <div className="cell">
                  <div className="elementicon"><img src={phone} alt="" className='icons-address'/></div>
                  <h4 className="elementtext">+91 629-071-9840</h4>
                </div>

                <div className="cell">
                  <h4 className="elementicon"><a href=" https://www.facebook.com/profile.php?id=61556521663523&mibextid=ZbWKwL" target="-blank" >
                    <img src={facebook} alt="" className='icons-address'/>  
                  </a></h4>
                </div>

                <div className="cell">
                  <div className="elementicon"><img src={mail} alt="" className='icons-address'/></div>
                  <h4 className="elementtext">tgiacademic@gmail.com</h4>
                </div>

                <div className="cell">
                  <h4 className="elementicon"><a href="https://www.youtube.com/@TECHSPROGLOBALINSTITUTE" target="-blank" >
                    <img src={youtube} alt="" className='icons-address'/>  
                  </a></h4>
                </div>
              
              </div>




            <div className="lower">

                <h6 className="text">DISCLAIMER: The contents, images, pictures, graphics etc. On the site could include technical inaccuracies or typographical errors. Correction and changes are periodically made to the information herein. Techspro Global Institute may make improvements and/or changes herein at any time at its sole discretion without notice. Techspro Global Institute makes no representations about the accuracy of the information contained in the contents, documents and graphics on the site for any purpose. All contents, documents and graphics are provided “as is”. Techspro Global Institute hereby disclaims all warranties and conditions with regard to this information, including all implied warranties and conditions of merchantability, fitness for any particular purpose, title and non-infringement. In any event, Techspro Global Institute Global Institute shall not be liable to any party for any direct, indirect, special or other consequential damages for any use of the site, the information, or on any other hyperlinked web site, including, without limitation, any loss of profits, business interruption, loss of programs or other data on your information handling system or otherwise.</h6>

            </div>

        </div>
    )
}