import React from 'react';
import '../Coursesstyle.css';
import Header from "../../Header";
import vfx from "../../../Assets/Images/Vfx.jpg";
import id from "../../../Assets/Images/ID.jpg";

export default function Designdiploma() {
  return (
    <>
        <Header/>
        

        <div className='advwrapper'>
        <h1 className='coursestopic'>Diploma Courses in Designing</h1>


          <div className='coursedesctiptioncontainer'>
          
            <div className='coursedescription'>
              <h2>Diploma in Interior Designing with AutoCAD, SketchUp, 3Ds Max, V-ray and Vaastu</h2>
              Interior design is the art and science of enhancing the interior of a space to achieve a healthier and more aesthetically pleasing environment for the people using it. It involves the selection and arrangement of furniture, color schemes, lighting, textiles, and other elements to create functional and visually appealing spaces. Interior designers often work with architects, contractors, and clients to design spaces that meet the specific needs and preferences of the occupants while also considering factors such as safety, accessibility, and sustainability.<br/><br/>

              Eligibility: This course is suitable for anybody having creative minds and capacity to hard work.<br/><br/>

              Course Duration: 12 Months<br/><br/>

              Software : AutoCAD, SketchUp, 3dsMax and V-Ray



            </div>
            <div className='courseimagecontainer'><img src={id} alt="" className="courseimg" /></div>

          </div>

          <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Diploma in 2D Animation and VFX</h2>
            VFX stands for Visual Effects. It refers to the process of creating, manipulating, or enhancing imagery for film, television, video games, and other forms of media. VFX involves a variety of techniques, including computer-generated imagery (CGI), compositing, matte painting, animation, motion capture, and more. VFX artists use specialized software and tools to produce stunning visual elements that can range from realistic creatures and environments to fantastical worlds and impossible events. 	VFX is an integral part of modern filmmaking and entertainment, enhancing storytelling and immersing audiences in visually captivating experiences.<br/><br/>

            Eligibility: This course is suitable for anybody having creative minds and capacity to hard work.<br/><br/>

            Course Duration: 12 Months<br/><br/>

            Software: Photoshop CC, Animate CC, Audition CC, Premiere Pro CC, Nuke




          </div>
          <div className='courseimagecontainer'><img src={vfx} alt="" className="courseimg" /></div>

        </div>



          
        </div>
    </>
  );
}
