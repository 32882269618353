import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyDo7Tx7GlLbY54Mk8IRJWWXBEYd7NzmONg",
  authDomain: "techspro-3200f.firebaseapp.com",
  projectId: "techspro-3200f",
  storageBucket: "techspro-3200f.appspot.com",
  messagingSenderId: "761503849166",
  appId: "1:761503849166:web:c8040d4a761a60c7260e9f"
};

// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);
