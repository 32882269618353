import React, { useState, useEffect, useRef } from 'react';
import "./Dropdownmenu.css";
import Designdd from './Mini/designdd.jsx';
import Mechanicaldd from './Mini/mechanicaldd.jsx';
import { Link } from "react-router-dom";

export default function DropDown() {
    const [openDesign, setOpenDesign] = useState(false);
    const [openMechanical, setOpenMechanical] = useState(false);


    const designRef = useRef();
    const mechanicalRef = useRef();

    const handleClickOutside = (event) => {
        if (designRef.current && !designRef.current.contains(event.target)) {
            setOpenDesign(false);
        }
        if (mechanicalRef.current && !mechanicalRef.current.contains(event.target)) {
            setOpenMechanical(false);
        }
        
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="ddmenubox">
            <div className="ddmenulist">
                <Link to="/Automobileadvdiploma" className="ddmenulistelement">Automobile Courses</Link>
                <div ref={mechanicalRef}>
                    <Link to="" className="ddmenulistelement" onClick={() => setOpenMechanical(prev => !prev)}>Mechanical Courses</Link>
                    {openMechanical && <Mechanicaldd />}
                </div>
                <div ref={designRef}>
                    <Link to="" className="ddmenulistelement" onClick={() => setOpenDesign(prev => !prev)}>Design Courses</Link>
                    {openDesign && <Designdd />}
                </div>
            </div>
        </div>
    );
}
