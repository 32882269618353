import React from "react"
import "./designdd.css";
import { Link } from "react-router-dom";

export default function Designdd(){
    return(
        <div className="designddmmenubox">
            <div className="designddmmenulist">
                <Link to="/Designcertificate" className="designddmmenulistelement">Certificate </Link>
                <Link to="/Designdiploma" className="designddmmenulistelement">Diploma </Link>
                <Link to="/Designadvdiploma" className="designddmmenulistelement">Adv. Diploma </Link>
            </div>
        </div>
    )
}