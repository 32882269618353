import Footer from "./Components/Footer";
import Home from "./Components/Home";
import AboutUs from "./Components/AboutUs";

import Designcertificate from "./Components/CoursePages/Design/Design-Certificate-courses";
import Designadvdiploma from "./Components/CoursePages/Design/Design-advdiploma-courses";
import Designdiploma from "./Components/CoursePages/Design/Design-diploma-courses";

import Mechanicaldiploma from "./Components/CoursePages/Mechanical/Mechanical-diploma-courses";
import Mechanicalcertificate from "./Components/CoursePages/Mechanical/Mechanical-Certificate-courses";
import Mechanicaladvdiploma from "./Components/CoursePages/Mechanical/Mechanical-advdiploma-courses";

import Automobileadvdiploma from "./Components/CoursePages/Automobile/Automobile-advdiploma-courses";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />

        <Route path="/Designcertificate" element={<Designcertificate />} />
        <Route path="/Designdiploma" element={<Designdiploma />} />
        <Route path="/Designadvdiploma" element={<Designadvdiploma />} />

        <Route path="/Mechanicalcertificate" element={<Mechanicalcertificate />} />
        <Route path="/Mechanicaldiploma" element={<Mechanicaldiploma />} />
        <Route path="/Mechanicaladvdiploma" element={<Mechanicaladvdiploma />} />

        <Route path="/Automobileadvdiploma" element={<Automobileadvdiploma />} />


      </Routes>
      <Footer />
    </div>
  );
}

export default App;
