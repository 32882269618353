import React from 'react';
import '../Coursesstyle.css';
import Header from "../../Header";  
import adv from "../../../Assets/Images/Mech-03.jpg";

export default function Mechanicaladvdiploma() {
  return (
    <>
        <Header/>
        

          <div className='advwrapper'>
          <h1 className='coursestopic'>Advanced Diploma Courses in Mechanical</h1>
            <div className='coursedesctiptioncontainer'>
            
              <div className='coursedescription'>
                <h2>Advanced Diploma in Tool & Die Making (Dies & Moulds)</h2>
                Making tools and dies is an interesting combination and such specialized knowledge is valued in the design and production of machine-made goods. The work involves mass-producing solid items using dyes and moulds that are created through the design and manufacturing process. A Master diploma in tool and die making will develop your leadership skills in addition to teaching you the skill set needed on a professional level. Solid tools are a part of our daily lives, so there is a great demand in the tool and die making industries for highly skilled workers who can interact with clients.
                <br/><br/>

                Scope :		Manufacturing sector.
                <br/><br/>
                Duration :		24 Months

              </div>
              <div className='courseimagecontainer'><img src={adv} alt="" className="courseimg" /></div>

            </div>

            
          </div>
          


    </>
  );
}
