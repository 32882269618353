import React, { useState } from "react";

import { getFirestore, addDoc, collection} from "firebase/firestore";

import './Home.css';
import Header from "./Header";
import back from "../Assets/Images/Landing-page-hero.jpg";
import back2 from "../Assets/Images/Landing-section2-back.jpg";
import back3 from "../Assets/Images/Landing-section3-back4.jpg";
import front from "../Assets/Images/Landing-section3-small.jpg";
import bookmark from "../Assets/Icons/bookmark-solid.svg";

export default function Home() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail  ] = useState('');

    const db = getFirestore();



    const saveData = async () => { 
        
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (!emailRegex.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }
    
        await addDoc(collection(db,"Registered_Users"), {
            Name: name,
            Phone: phone,
            Email: email,
        });
    
        alert("Details Registered");
        document.getElementById('name').value = "";
        document.getElementById('email').value = "";
        document.getElementById('phone').value = "";
    }
    


    
    return (
        <>
        <Header/>
        <div className="homewrapper">
            <div className="Sections1" style={{
                backgroundSize: `cover`,
                backgroundImage: `url(${back})`
            }}>
                <div className="segment1">
                    <h1 className="Tag">Change your today into better tomorrow</h1>
                    <h3 className="knowmore">Know More</h3>
                </div>
                <div className="segment2">
                    <div className="registrationBox">
                        <div className="registrationInside">
                            <h1 className="registrationhead">Register for details</h1>
                            <h4 className="registrationheaddesc">Enter your details for consultation</h4>
                            <div className="inputfields">
                                <input type="text" id="name" name="Name" placeholder=" Name" className="textbox" value={name} onChange={(e) => setName(e.target.value)} required autoComplete="off"/>
                                <input type="text" id="email" name="Email" placeholder=" Email" className="textbox" value={email} onChange={(e) => setEmail(e.target.value)} required autoComplete="off"/>
                                <input type="text" id="phone" name="Phone" placeholder=" Phone" className="textbox" value={phone} onChange={(e) => setPhone(e.target.value)} required autoComplete="off"/>
                                <button onClick={saveData} className="submit">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Sections2'>

            

                <div className='segment'>
                    <h1 className='segmenthead'>What We Offer</h1>
                    <h3 className='segmenthead2'>We take the privilege to introduce our premier organization Techspro Global Institute, which is a professionally managed Educational Institute being involved in skill enhancement based vocational education and training associated with PBSSD ( Paschim Banga Society for skill Development) providing training to the Youths/ Students / Professionals / Home Makers.</h3>
                    <div className='segmentcontent'>
                        <div className='segmentcontenthalf'>
                            <div className='segmentcontentunit'>
                                <div className='segmenticon'>
                                    <img src={bookmark} alt="" className='icons-address'/>
                                </div>
                                <h3 className='segmenttext'>Industry based educational institute</h3>
                            </div>
                            <div className='segmentcontentunit'>
                                <div className='segmenticon'><img src={bookmark} alt="" className='icons-address'/></div>
                                <h3 className='segmenttext'>Opportunity of OJT (On Job Training)</h3>
                            </div>
                            <div className='segmentcontentunit'>
                                <div className='segmenticon'><img src={bookmark} alt="" className='icons-address'/></div>
                                <h3 className='segmenttext'>Earning while learning</h3>
                            </div>
                        </div>
                        <div className='segmentcontenthalf'>
                            <div className='segmentcontentunit'>
                                <div className='segmenticon'><img src={bookmark} alt="" className='icons-address'/></div>
                                <h3 className='segmenttext'>Industry standard Course Curriculum</h3>
                            </div>
                            <div className='segmentcontentunit'>
                                <div className='segmenticon'><img src={bookmark} alt="" className='icons-address'/></div>
                                <h3 className='segmenttext'>Experienced Mentors</h3>
                            </div>
                            <div className='segmentcontentunit'>
                                <div className='segmenticon'><img src={bookmark} alt="" className='icons-address'/></div>
                                <h3 className='segmenttext'>Site Visit</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div  > <img src={back2} alt="" className='segmentimg'/></div>
                
            </div>
            <div className='Sections3wrapper1'style={{
                    backgroundSize: `cover`,
                    backgroundImage: `url(${back3})`,
                    scale: `100%`
            }}>
                <div className='Sections3wrapper2'>
                    <div className='Sections3' >
                        <div className='section3img' style={{
                                backgroundSize: `cover`,
                                backgroundImage: `url(${front})`
                            }}></div>
                        <div className='section3content'>
                            <h1 className='section3contenthead'>The Vision</h1>
                            <h3 className='section3contentbody'>To be one of the premier educational institute that helps the students to gain contemporary knowledge by innovative learning, on job training, handholding support for enhancement of skills of the youths, 100% placement assistance, entrepreneurship, self –employment, linked employment and sensitivity to face the challenges in life both in academic field as well as in their personal life by providing qualitative education making themselves – sufficient and self-dependent in life.</h3>
                        </div>



                        
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
