import React from 'react';
import '../Coursesstyle.css';
import Header from "../../Header";

import graphics from "../../../Assets/Images/graphics.jpg";
import uiux from "../../../Assets/Images/UI-02.jpg";
import web from "../../../Assets/Images/web.jpg";
import cad from "../../../Assets/Images/cad.jpg";
import photoshop from "../../../Assets/Images/photoshop.jpg";
import max from "../../../Assets/Images/max.jpg";
import vray from "../../../Assets/Images/vray.jpg";
import illustrator from "../../../Assets/Images/illustrator.jpg";
import lumion from "../../../Assets/Images/lumion.jpg";
import revit from "../../../Assets/Images/revit.jpg";
import sketchup from "../../../Assets/Images/Vfx.jpg";


export default function Designcertificate() {
  return (
    <>
        <Header/>
        

        <div className='advwrapper'>
        <h1 className='coursestopic'>Certificate Courses in Designing</h1>


          <div className='coursedesctiptioncontainer'>
          
            <div className='coursedescription'>
              <h2>Certificate in Graphics Designing</h2>
              Graphic design is a creative process that involves combining text, images, symbols, and other elements to communicate a message or convey information visually. It is to create designs for a wide range of purposes, including advertising, branding, packaging, web design, and print media.
              Graphic designers use software along with traditional art techniques, to create visually appealing and effective designs.
              Graphic design plays a crucial role in shaping how people perceive brands, products, and ideas, and it is often used to create visual identities that help businesses stand out in a crowded marketplace. From logos and advertisements to brochures and websites, graphic design is ubiquitous in modern society and is essential for conveying information in an engaging and memorable way.<br/><br/>


              Eligibility: This course is suitable for anybody having creative minds and capacity to hard work<br/><br/>

              Course Duration: 06 Months<br/><br/>

              Software: Adobe Photoshop, Adobe Illustrator, CorelDraw, InDesign 


            </div>
            <div className='courseimagecontainer'><img src={graphics} alt="" className="courseimg" /></div>

          </div>

          <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Certificate in UI/UX Designing</h2>
            UI/UX development refers to the process of creating user interfaces (UI) and user experiences (UX) for digital products such as websites, mobile applications, and software. UI design focuses on the look and feel of the product, including layout, typography, color schemes, and visual elements, while UX design focuses on the overall experience of the user, including usability, accessibility, and interaction design.
            It is a dynamic and iterative process that requires a deep understanding of user needs, creative problem-solving skills.<br/><br/>


            Eligibility: This course is suitable for anybody having creative minds and capacity to hard work.<br/><br/>
            Course Duration: 12 Months<br/><br/>

            Software: XD, PhotoshopCC, IllustratorCC , Figma , Sketch, Visual Code .<br/><br/>

            Scope: Front-End developer, Back-End developer, Full-stack Developer, E-Commerce Developer, QA Tester 




          </div>
          <div className='courseimagecontainer'><img src={uiux} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Certificate in Web Designing</h2>
            Web development is the process of creating and maintaining websites or web applications that are accessible via the internet. It involves a combination of programming, design, and content creation to produce a functional and visually appealing website. Web development encompasses several aspects, including:
            Front-End, Back-End, Full-Stack Development and  Database management. It also includes Responsive design and Optimization also.<br/><br/>

            Eligibility: This course is suitable for anybody having creative minds and capacity to hard work.<br/><br/>

            Course Duration: 12 Months<br/><br/>

            Software: HTML, CSS, JavaScript, React.js, Angular, JQuery, Bootstrap,   PHP, Python, MySQL, vsCode, MongoDB WordPress Webhosting.
            Scope: Front-End developer, Back-End developer, Full-stack Developer, E-Commerce Developer, QA Tester 





          </div>
          <div className='courseimagecontainer'><img src={web} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Auto CAD - Interior</h2>
            AutoCAD is a general drafting and design application used in industry by architects, project managers, engineers, graphic designers, city planners, and other professionals to prepare technical drawings.It allows the creation and modification of geometric models with an almost infinite capacity to develop all types of structures and objects.<br/>

            This ability to work in different fields has made AutoCAD transcend its traditional use in the world of architecture and engineering, to enter the world of graphic and interior design.
            AutoCAD is sophisticated CAD software that is synonymous with engineering drafting. The concept of AutoCAD evolved way back in the 1980’s, when engineers and architects were seeking to harness the power of newly introduced personal computers to reduce the drafting time. People began experimenting with internal graphic controllers which allowed them to draw engineering / architectural drawings at the front end which were efficiently replicated at the back end of the computer. AutoCAD was formally launched in December 1982 by Autodesk, a leader in 3D design, engineering and entertainment software.<br/><br/>

            Duration : 3 Months<br/><br/>
            





          </div>
          <div className='courseimagecontainer'><img src={cad} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Photoshop</h2>
            Photoshop is an image creation, graphic design and photo editing software developed by Adobe. The software provides many image editing features for pixel-based images, raster graphics and vector graphics.
            Adobe Photoshop is a software application for image editing and photo retouching for use on Windows or MacOS computers. Photoshop offers users the ability to create, enhance, or otherwise edit images, artwork, and illustrations. Changing backgrounds, simulating a real-life painting, or creating an alternative view of the universe are all possible with Adobe Photoshop. It is the most widely used software tool for photo editing, image manipulation, and retouching for numerous image and video file formats. The tools within Photoshop make it possible to edit both individual images as well as large batches of photos. <br/><br/>

            Duration : 3 Months<br/><br/>
          



          </div>
          <div className='courseimagecontainer'><img src={photoshop} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>3Ds Max
            </h2>
            Autodesk 3ds Max, commonly known as 3ds Max, is a professional 3D computer graphics program for making 3D animations, models, games, and images. Developed and produced by Autodesk Media and Entertainment, it has become one of the most widely used software tools in the field of 3D design and animation.3ds Max is a powerful and versatile tool that is essential in various industries for 3D modeling, animation, and rendering. Its comprehensive set of features and integration capabilities make it a valuable asset for professionals looking to create high-quality 3D content.<br/><br/>

            Duration : 6 Months<br/><br/>


            Scope: Game Development industry, Film and TV Industry, Architectural and Interior  visualization , Creating detailed 3D models of products for prototyping.




          </div>
          <div className='courseimagecontainer'><img src={max} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>V-ray
            </h2>
            V-Ray is a commercial plug-in for third-party 3D computer graphics software applications and is used for visualizations and computer graphics in industries such as media, entertainment, film and video game production, industrial design, product design and architecture.
            Chaos® V-Ray® is a 3D rendering plugin available for SketchUp, 3ds Max, Maya, Cinema 4D, Rhino, Revit, and more. It is widely used in architectural visualization, advertising, and visual effects for film and television. With V-Ray, artists and designers can explore and share their projects with real-time ray tracing and render high-quality visualizations.<br/><br/>

            Duration:  03 Months <br/><br/>
            Scope:  Architectural visualization,Interior design,Product design, Automotive, Advertising, Games,Television VFX,Film VFX





          </div>
          <div className='courseimagecontainer'><img src={vray} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Illustrator           </h2>
            Adobe Illustrator is a software application for creating drawings, illustrations, and artwork using a Windows or MacOS computer. Illustrator was initially released in 1987 and it continues to be updated at regular intervals, and is now included as part of the Adobe Creative Cloud. Illustrator is widely used by graphic designers, web designers, visual artists, and professional illustrators throughout the world to create high quality artwork. Illustrator includes many sophisticated drawing tools that can reduce the time need to create illustrations.<br/><br/>
            Duration: 03 Months<br/><br/>
            





          </div>
          <div className='courseimagecontainer'><img src={illustrator} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Lumion
            </h2>
            Lumion is a Visualization Software designed for creating realistic and immersive renderings, providing solutions for Architects, Landscapers, Urban Planners, and Interior Designers. It allows users to showcase and communicate Projects by transforming their models into captivating and lifelike images and videos.
            Boasting a revamped Raytracing rendering engine, real-time model Import, a diverse asset Library, and a wide selection of Output options, you have all the tools and flexibility you need for seamless Project execution.
            From beautiful buildings and cozy interiors to sweeping landscapes and large-scale urban projects, Lumion has all the tools, content, and effects to help you bring your designs to life in stunning quality.<br/><br/>

            Duration: 06 Months<br/><br/>
            Scope:	Building architecture, Landscape architecture, Interior design, Urban planning,3D rendering





          </div>
          <div className='courseimagecontainer'><img src={lumion} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Revit
            </h2>
            Revit is a software developed for planning, designing, constructing, and managing buildings and infrastructure with powerful BIM tools. It was developed by Charles River in the year 1997 it was acquired by Autodesk in May 2000. Revit, which became Autodesk Revit, is now the primary choice of architects, landscape designers, structural engineers, civil engineers, designers, and contractors. 
            Revit is used for design and documentation solutions purposes as it supports all phases and disciplines involved in a building project. The Revit software has the tools to plan and track various stages in the building's lifecycle, from concept to construction. Revit tools are also put to use for maintenance and renovation purposes. The following are the functions that can be carried out using Autodesk Revit, 
            Revit is a Building Information Modeling (BIM) software that has revolutionized the construction industry. It is widely used by architects, engineers, contractors, and builders to design, visualize, and simulate building projects. Revit has many benefits for different trades, which we will discuss in this blog.<br/><br/>

            Duration: 06 Months 




          </div>
          <div className='courseimagecontainer'><img src={revit} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>SketchUP
            </h2>
            SketchUp is 3D modeling software that allows users to create and manipulate 3D models of buildings, landscapes, furniture, and other objects. It is commonly used in architecture and interior design.
            One of the biggest benefits of SketchUp is its intuitive interface, which makes it easy for beginners to get started and for professionals to work quickly. The software is designed to be user-friendly, with simple tools and shortcuts that allow you to create 3D models with precision and speed.<br/><br/>
            Duration: 03 Months
          





          </div>
          <div className='courseimagecontainer'><img src={sketchup} alt="" className="courseimg" /></div>

        </div>

        



          
        </div>
    </>
  );
}
