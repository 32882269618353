import './AboutUs.css';
import Header from "./Header";

import legacy from "../Assets/Images/Legacy.jpg";
import mission from "../Assets/Images/Mission.jpg";
import vision from "../Assets/Images/Vision-3.jpg";
import facultyimg from "../Assets/Images/PrincipalsDesk - Copy.jpeg";
import facultyimg2 from "../Assets/Images/MDsDeskcopy.jpg";
import facultyimgDS from "../Assets/Images/DS.jpg";
import facultyimgSB from "../Assets/Images/SB.jpg";
import facultyimgLS from "../Assets/Images/LS.jpg";
import facultyimgTA from "../Assets/Images/TA.jpg";
import facultyimgSP from "../Assets/Images/SP.jpeg";

import left from "../Assets/Icons/quote-left-solid.svg";
import right from "../Assets/Icons/quote-right-solid.svg";

export default function AboutUs(){
    return(
        <>
        <Header/>
        <div className='sectionswrapper'>
       
            <div className='section1'>
                <h1 className='topic'>About Us</h1>
                        <h4 className='content'>
                            We take the privilege to introduce our premier organization, Indtech Enterprise which is a professionally managed manufacturing enterprise by 1500 sqft space and by all required machines, tools and equipment located at 127A & 127B Motilal Gupta Road, Kolkata – 700008, West Bengal, being involved in production of - Plastic injection Moulds, Press Tools, Dies, Jigs and Fixture, Mechanical Accessories and Spare Parts with excellent client base across pan India. 
                            Secondly we also involved in  skill enhancement based vocational education and training since the year 1997 to 2017 associated with The George Telegraph  Training Institute and NSHM as Franchisee and conferred Certificate, Advance Certificate, Diploma, Advance Diploma in various multiskill & upskilling professional courses.<br/><br/>
                            Operating since 2018 the organization has presence in Kolkata and since its inception the manufacturing segment is   producing the above mentioned items and delivering the same at the different locations of our Country; and the training segment which was associated with The George Telegraph Training Institute and NSHM as Franchisee have trained more than 1500 candidates out of which 70% have placed at the respective Industries.<br/><br/>
                            For benefit of the society, benefit of the youths of rural and urban areas of West Bengal, we are willing to reconstruct our own educational segment by the name of “TECHSPRO GLOBAL INSTITUTE (Sister concern of  Indtech Enterprise) which will may beneficial for the youths, will may increase their acceptability in the respective industries , as we are ready to organize learning, on job training, enhancement of skills of the youths, 100% placement support , entrepreneurship / self –employment and linked employment.<br/><br/>
                        </h4>
                
            </div>

        



            <div className='sections'>
                

                <div className='sectionhalftext'>

                    <h1 className='topic'>Our Legacy</h1>
                    <h4 className='content'>
                    With over two decades of empowering creative minds, indtech enterprise stands tell as a renowned institute, embracing entrepreneurship and employability with country as it platform. As the industry thrives amidst advancing technology and strategic shifts, the institute, since it’s inception in 1997, has remained at the forefront, creating to ever changing creative needs of the automotive, mechanical & designing industry.
                    Techspro Global Institute (Education wings of Indtech Enterprise) offered course equip our students with the essential skill set to drive growth, innovation, and social development. Pioneering to the Indian market, we blend <br/>
                    <li>Industry based educational institute<br/></li>
                    <li>Providing the opportunity of OJT<br/></li>
                    <li>Earning while Learning<br/></li>
                    <li>Industry Standard Curriculum<br/></li>
                    <li>Experienced Mentors with Technical Expertise.<br/></li>
                    <li>Opportunity of hybrid learning.<br/></li>
                    

                    The future awaits an intensely competitive market, and Techspro Global Institute continues to pave the way for excellence, ensuring that our students spearhead industry success with utmost confidence and prowess.<br/>
                    </h4>
                </div>

                <div className='sectionhalfimg'>
                <img src={legacy} alt="" className="img" />
                </div>
            </div>


            <div className='sections'>
                <div className='sectionhalfimg'>
                <img src={mission} alt="" className="img" />
                </div>

                <div className='sectionhalftext'>

                    

                    <h1 className='topic'>Our Mission</h1>
                    <h4 className='content'>
                    For benefit of the society, benefit of the youths of rural and urban areas of west bengal, we are willing to reconstruct our own educational segment by the name of “TECHSPRO GLOBAL INSTITUTE (sister concern of  Indtech Enterprise) which will may beneficial for the youths, will may increase their acceptability in the respective industries  as we are ready to organize learning, on job training, enhancement of skills of the youths by directly involve them in production at the own workshop , 100% placement support , entrepreneurship / self –employment and linked employment.<br/><br/>
                    </h4>

                    
                </div>
            </div>

            <div className='sections'>
                

                <div className='sectionhalftext'>
                    <h1 className='topic'>Our Vision</h1>
                    <h4 className='content'>
                    To be one of the premier educational institute that helps the students to gain contemporary knowledge by Innovative Learning, On Job Training, Handholding Support for Enhancement of Skills of the Youths,100% Placement Assistance, Entrepreneurship, Self –employment , Linked employment and sensitivity to face the challenges in life both in academic field and as well as in their personal life by providing qualitative education making themselves – sufficient in life.<br/><br/>
                    </h4>
                </div>

                <div className='sectionhalfimg'>
                <img src={vision} alt="" className="img" />
                </div>
            </div>

            

            <div className='aboutusdivider'></div>
            
            




            <div className='aboutussection3'>
            
                <div className='aboutussection3element'>
                    <div className='aboutussection3img'><img src={facultyimg} alt="" className="facultyimg" /></div>
                    <div className='aboutussection3text'>
                        <h1 className='aboutussection3topic'>From the Principal's Desk</h1>
                        <h4 className='aboutussection3content'>
                        <img src={left} alt=  "" className='quote-left'/>
                        Institute is the field of human activities, where we the teachers try every nerve, to ensure that student gets full opportunity for blossoming of the hidden faculties in him / her. To name a few classroom teaching, on job training, participation in internship etc. have been some of the methods adopted by us in developing the student’s personality and the result so far are good. In our venture to see the student grow with all their potential that we have been enjoying the worm cooperation from them. 
                        In the meantime, we are leaving in a global village and our country, despite all problems and failures, is rich in two resources- human resources and natural resources. The more we develop the human resource the better we can serve the nation. We are sure that with a clear vision, whole hearted altogether effort and full dedication our institute family will be able to reach at the destination which is to serve the society and the nation as a whole.
                        <img src={right} alt="" className='quote-right'/>
                        </h4>
                    </div>
                </div>

                <div className='aboutussection3element'>
                    <div className='aboutussection3img'><img src={facultyimg2} alt="" className="facultyimg" /></div>
                    <div className='aboutussection3text'>
                        <h1 className='aboutussection3topic'>From the MD's Desk</h1>
                        <h4 className='aboutussection3content'>
                        <img src={left} alt="" className='quote-left'/>
                        It is the motto of “Techspro Global Institute to impart quality education that teaches the students to learn the value of integrity and honesty, to think rationally and to judge logically. In this institution we believe that pursuit of knowledge is not the only aim of education. Stuffing the students with related to the subject is useless until we can teach the student how to use this knowledge. Thus acquisition of knowledge from the books is not enough. One must be taught how to modify and mould this knowledge to suit one’s need. Knowledge must be acquired with the mission of using it not only for one’s own benefit but also for the uplift of the society and prosperity of the nation.
                        <img src={right} alt="" className='quote-right'/>
                        </h4>
                    </div>
                </div>

            </div>




            <h1 className='facultyheader'>Our Mentors</h1>

            <div className='aboutussection4'>
            
            <div className='aboutussection4element'>
                <div className='aboutussection4img'><img src={facultyimgTA} alt="" className="facultyimg2" /></div>
                <div className='aboutussection4text'>
                    <h1 className='aboutussection4topic'>Tanmoy Adhikary</h1>
                    <h5 className='aboutussection4content'>
                    Diploma engineer having more than 35 years of shop floor managing experience, in sheet metal dies, plastic moulds, jigs and fixtures, gauge's and teaching experience in ITI, Workshop practical, engineering drawing and cad cam .
                    </h5>
                </div>
            </div>

            <div className='aboutussection4element'>
                <div className='aboutussection4img'><img src={facultyimgDS} alt="" className="facultyimg2" /></div>
                <div className='aboutussection4text'>
                    <h1 className='aboutussection4topic'>Dhruba Sarkar</h1>
                    <h5 className='aboutussection4content'>

                    He did both his Bachelors Masters degrees in Architecture from Jadavpur University, Kolkata. He himself has been a practicing architect; interior designer for over 2 decades. He has been a lecturer/ professor of interior designing for more than 2 decades.
                    </h5>
                </div>
            </div>

            <div className='aboutussection4element'>
                <div className='aboutussection4img'><img src={facultyimgLS} alt="" className="facultyimg2" /></div>
                <div className='aboutussection4text'>
                    <h1 className='aboutussection4topic'>Pandit Loknath Sastri</h1>
                    <h5 className='aboutussection4content'>
                    Vaastu Shastra is considered the most scientific  application of ancient India.
                    Pandit Loknath Sastri a legendary Maestro in Vaastu Shastra. Pandit Sastri is relentlessly catering his knowledge and experience for relief of problems and enormous success in life of general people who is easily accessible and famous for his mild and gentle behavior.
                    Panditji has been a faculty across several institutions spanning across  40 years. He has successfully trained several students in Vaastu Shastra who have eventually  went on to become established in this profession in India and across the globe.
                    Panditji's goal is to serve humanity with his knowledge of Vaastu Shastra and help people lead better and more meaningful lives.
                    </h5>
                </div>
            </div>

            <div className='aboutussection4element'>
                <div className='aboutussection4img'><img src={facultyimgSB} alt="" className="facultyimg2" /></div>
                <div className='aboutussection4text'>
                    <h1 className='aboutussection4topic'>Subhajit Bhattacharyya</h1>
                    <h5 className='aboutussection4content'>

                    Subhajit Bhattacharyya is a seasoned professional with an impressive 22 years of experience in the dynamic and ever-evolving field of simulation industry . He is a qualified personnel with a degree of Graduate in Science.
                    As a dedicated mentor, he has played a pivotal role in shaping the next generation of professionals in the simulation industry. His wealth of knowledge and hands-on experience has not only enriched his own career but has also been instrumental in guiding and inspiring his students to excel in this complex and rapidly advancing field.Notably, he has served as a mentor at esteemed institutions such as EXIN, The George Telegraph Institute of Interior Designing, and INIFD,Webel Informatics,Punjab Technical University leaving an indelible mark on the educational landscape


                    </h5>
                </div>
            </div>


            <div className='aboutussection4element'>
                <div className='aboutussection4img'><img src={facultyimgSP} alt="" className="facultyimg2" /></div>
                <div className='aboutussection4text'>
                    <h1 className='aboutussection4topic'>Ar. Souvik pal</h1>
                    <h5 className='aboutussection4content'>

                    Architect and Interior Designer having more than 20 years of experience in the dynamic and ever evolving field of construction sector and allied industries. He is a qualified professional with degree of B. Architecture from M.I.E.T Nagpur and Diploma in Interior Designing from JENSON & NICHOLSON INDIA LTD. & EXTERIOR INTERIORS. At present he is the proprietor of two firms namely Messrs. VAASTU & Messrs. S S GROUP basically involved in metamorphosis vacant land into a modern or classical way as per the need by the architects into an elegant and comfortable dwelling place and the space – THE SHELTER by designs and enriched craftsmanship quality interiors and the architects and designers with other members including structural engineers, vastu consultants, turnkey executors, surveyors, contractors and workers have been dwelling in this habitat from more than two decades. Notably Ar. Souvik Pal is serving the role of Mentor at the esteemed Institution Exterior Interior Limited (EXIN) since 2007 onwards leaving an indelible mark of educational landscape.


                    </h5>
                </div>
            </div>

        </div>
            
            </div>
            </>
       
    )
}