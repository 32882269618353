import React from 'react';
import '../Coursesstyle.css';
import Header from "../../Header";
import dmd from "../../../Assets/Images/DMD-01.jpg";
import dmf from "../../../Assets/Images/DMF-01.jpg";

export default function Mechanicaldiploma() {
  return (
    <>
        <Header/>
        

        <div className='advwrapper'>
        <h1 className='coursestopic'>Diploma Courses in Mechanical</h1>


          <div className='coursedesctiptioncontainer'>
          
            <div className='coursedescription'>
              <h2>Diploma in Mechanical Fitter / Machinist with CAM (VMC, CNC Turnning, Wire EDM, EDM Spark.)</h2>
              CNC stands for computer numerical control, which is a computerized manufacturing process designed to automate the production of goods. CNC machines are pre-programmed (using software and code) to control the movement of equipment and create products that meet exact specifications. This process ensures the precise, seamless production of goods—and is therefore propelling the future of manufacturing.
              VMC machines, also known as Vertical Machining Centers, are highly advanced and versatile machines that are used for metal cutting and other metalworking operations. These machines are designed for a wide range of applications, including drilling, tapping, milling, and boring.
              CNC (Computer Numerical Control) and VMC (Vertical Machining Center) programming certification entails proficiency in controlling automated machine tools, such as mills, lathes, and cutting machines, using computer-driven software.
             

              <br/><br/>

              Course Duration: 12 Months<br/><br/>

              Software : 



            </div>
            <div className='courseimagecontainer'><img src={dmf} alt="" className="courseimg" /></div>

          </div>

          <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Diploma in Mechanical Draftsmanship with CAD( Auto CAD 2D, 3D & Solid Works)</h2>
            A diploma in mechanical draftsmanship with CAD can help students develop skills in computer-aided design (CAD) and drafting software, and learn how to create detailed mechanical drawings. The course can prepare students for careers as mechanical draftsmen in various industries. Some courses may also include modules on mechanical engineering, technical drawing, geometry, and tolerances
            <br/><br/>

            Course Duration: 12 Months<br/><br/>

            Software: Auto CAD 2D and  3D, Solid Works



          </div>
          <div className='courseimagecontainer'><img src={dmd} alt="" className="courseimg" /></div>

        </div>



          
        </div>
    </>
  );
}
