import React from 'react';
import '../Coursesstyle.css';
import Header from "../../Header";  
import adv from "../../../Assets/Images/adv.jpg";

export default function Designadvdiploma() {
  return (
    <>
        <Header/>
        

          <div className='advwrapper'>
          <h1 className='coursestopic'>Advanced Diploma Courses in Designing</h1>
            <div className='coursedesctiptioncontainer'>
            
              <div className='coursedescription'>
                <h2>Advanced Diploma in Interior Designing with AutoCAD, SketchUp, 3Ds Max, V-ray and Vaastu</h2>
                  Interior design is the art and science of enhancing the interior of a space to achieve a healthier and more aesthetically pleasing environment for the people using it. It involves the selection and arrangement of furniture, color schemes, lighting, textiles, and other elements to create functional and visually appealing spaces. Interior designers often work with architects, contractors, and clients to design spaces that meet the specific needs and preferences of the occupants while also considering factors such as safety, accessibility, and sustainability.<br/><br/>

                  Eligibility: This course is suitable for anybody having creative minds and capacity to hard work.<br/><br/>

                  Course Duration: 24 Months<br/><br/>

                  Software : AutoCAD, SketchUp, 3dsMax and V-Ray<br/><br/>
                  


              </div>
              <div className='courseimagecontainer'><img src={adv} alt="" className="courseimg" /></div>

            </div>

            
          </div>
          


    </>
  );
}
