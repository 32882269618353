import React from "react"
import "./mechanicaldd.css";
import { Link } from "react-router-dom";

export default function Mechanicaldd(){
    return(
        <div className="mechanicalddmmenubox">
            <div className="ddmmenulist">
                <Link to="/Mechanicalcertificate" className="designddmmenulistelement">Certificate </Link>
                <Link to="/Mechanicaldiploma" className="designddmmenulistelement">Diploma </Link>
                <Link to="/Mechanicaladvdiploma" className="designddmmenulistelement">Adv. Diploma </Link>
            </div>
        </div>
    )
}