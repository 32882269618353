import React from 'react';
import '../Coursesstyle.css';
import Header from "../../Header";

import power from "../../../Assets/Images/Power-02.jpg";
import solid from "../../../Assets/Images/Solid-02-A.jpg";
import cnc from "../../../Assets/Images/CNC-01.jpg";
import vmc from "../../../Assets/Images/VMC-01.jpg";
import auto from "../../../Assets/Images/Auto.jpeg";


export default function Mechanicalcertificate() {
  return (
    <>
        <Header/>
        

        <div className='advwrapper'>
        <h1 className='coursestopic'>Certificate Courses in Mechanical</h1>

          
        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Auto CAD -Mechanical (2D and 3D)</h2>
            AutoCAD Mechanical is a specialized version of AutoCAD, tailored specifically for mechanical engineering and design. It provides tools and features for creating 2D and 3D mechanical designs. It has provided an wide range of  Drafting and Annotation tools for creating detailed engineering drawings, including dimensions, annotations, and symbols and Tools for creating and editing 3D models, including parametric and direct modeling techniques.
            <br/><br/>
            Course Duration: 		06 Months
            <br/><br/>
            Scope:				Mechanical Engineer, Product Designer, CAD Technician, Simulation Engineer, Industrial Designer, Technical Support Specialist, PDM Administrator
					  Educator/Trainer.

          </div>
          <div className='courseimagecontainer'><img src={auto} alt="" className="courseimg" /></div>

        </div>




          <div className='coursedesctiptioncontainer'>
          
            <div className='coursedescription'>
              <h2>Solid Works</h2>
              SolidWorks is a comprehensive computer-aided design (CAD) and computer-aided engineering (CAE) software program developed by Dassault Systèmes. 
              SolidWorks is widely used for 3D solid modeling, allowing engineers and designers to create detailed 3D models of parts and assemblies. It provides tools for designing complex shapes and structures, making it suitable for a wide range of industries, including automotive, aerospace, consumer products, and industrial machinery.
              <br/><br/>

              Course Duration: 		06 Months
              <br/><br/>
              Scope:				Mechanical Engineer, Product Designer, CAD Technician, Simulation Engineer, Industrial Designer, Technical Support Specialist, PDM Administrator
                        Educator/Trainer.



            </div>
            <div className='courseimagecontainer'><img src={solid} alt="" className="courseimg" /></div>

          </div>

          <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>Power Shape</h2>
            Computer-aided manufacturing (CAM) software is used to create programs for CNC turning machines, which can then be used to manufacture precise components. CAM software takes a 3D model of a part as input and generates machine-specific instructions, or NC code, to guide the CNC machine. 
            In Conventional machine, operator controls the feed rate, spindle speed and other input parameters manually. but  In Numerically controlled machine, all . •CNC Turn can perform facing, turning, boring, grooving, threading, parting, roughing, chamfering and all most every operation on cylindrical work piece. through input parameters feeded in some specific format in NC directory and according to these input, M/C itself decide and control the all Machining parameters.
            <br/><br/>

            Course Duration: 		06 Months
            <br/><br/>
            Scope:				CAD Designer/Drafter, Mould and Tool Designer, Mechanical Engineer, Manufacturing Engineer, Product Designer, CAM Programmer, Reverse 						Engineer, 	Quality Control Specialist, Consultant.





          </div>
          <div className='courseimagecontainer'><img src={power} alt="" className="courseimg" /></div>

        </div>

        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>CAM - CNC Turning Programming and Operating</h2>
            Computer-aided manufacturing (CAM) software is used to create programs for CNC turning machines, which can then be used to manufacture precise components. CAM software takes a 3D model of a part as input and generates machine-specific instructions, or NC code, to guide the CNC machine. 
            In Conventional machine, operator controls the feed rate, spindle speed and other input parameters manually. but  In Numerically controlled machine, all . •CNC Turn can perform facing, turning, boring, grooving, threading, parting, roughing, chamfering and all most every operation on cylindrical work piece. through input parameters feeded in some specific format in NC directory and according to these input, M/C itself decide and control the all Machining parameters.
            <br/><br/>

            Course Duration: 		06 Months
            <br/><br/>
            Scope:				CAD Designer/Drafter, Mould and Tool Designer, Mechanical Engineer, Manufacturing Engineer, Product Designer, CAM Programmer, Reverse 						Engineer, 	Quality Control Specialist, Consultant.


            





          </div>
          <div className='courseimagecontainer'><img src={cnc} alt="" className="courseimg" /></div>

        </div>


        <div className='coursedesctiptioncontainer'>
          
          <div className='coursedescription'>
            <h2>VMC Programming and Operating</h2>
            VMC stands for Vertical Machining Center, which is a machine with vertically oriented tools that are used to machine raw metal blocks into components. VMC programming and operating skills can be learned through certification training, which can help individuals work efficiently and precisely in manufacturing.
            <br/><br/>


            Course Duration: 		06 Months
            <br/><br/>
            Scope:				VMC Operator, VMC Programmer, CNC Machinist, Manufacturing Engineer, Quality Control Inspector, Production Supervisor/Manager

            





          </div>
          <div className='courseimagecontainer'><img src={vmc} alt="" className="courseimg" /></div>

        </div>

        

        



          
        </div>
    </>
  );
}
