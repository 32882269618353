import React from 'react';
import '../Coursesstyle.css';
import Header from "../../Header";  
import adv from "../../../Assets/Images/Auto-Adv-01.jpg";

export default function Automobileadvdiploma() {
  return (
    <>
        <Header/>
        

          <div className='advwrapper'>
          <h1 className='coursestopic'>Advanced Diploma Courses in Mechanical</h1>
            <div className='coursedesctiptioncontainer'>
            
              <div className='coursedescription'>
                <h2>Advanced Diploma in Automobile Engg.</h2>
                Automobile Engineering is a fast moving sector requiring engineers who are conversant with the concept of product design, functional utility and integrated technology used in the manufacture of an automobile. Students of TGI ( Techspro Global Institute ) are trained with the relevant competencies and skills to find their niche in the industry. After the completion of this course Automobile Engineers are equipped to apply their skills in their careers.<br/>
                The new curriculum has been updated with the introduction of hybrid and electric vehicles, electronic vehicle management systems, Noise, Vibrations and Harshness, Machine learning, IOT etc.<br/><br/>

                  Course Duration: 24 Months<br/><br/>

                  Software : CATIA, CREO, AutoCAD, ABAQUS and ANSYS etc.<br/><br/>
                  


              </div>
              <div className='courseimagecontainer'><img src={adv} alt="" className="courseimg" /></div>

            </div>

            
          </div>
          


    </>
  );
}
