import './Header.css';
import DropDown from './DropDowns/Dropdownmenu';
import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from "../Assets/Icons/Web-logo.svg";
import mail from "../Assets/Icons/envelope-solid.svg";
import phone from "../Assets/Icons/phone-solid.svg";

export default function Header() {
    const [openMenu, setOpenMenu] = useState(false);
    const dropdownRef = useRef();

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="Header">
            <div className="UpperHeader">
                <Link to="/" className="individualLinks">
                    <div className="logo">
                        <img src={logo} alt="Website Logo" className='icons-phone'/>
                    </div>
                </Link>
                <div className="topHeaderContacts">
                    <div className="topHeaderContactElement">
                        <div className="topHeaderContactIcon">
                            <img src={phone} alt="Phone Icon" className='icons-phone'/>
                        </div>
                        <h4 className="topHeaderContactText">+91 629-071-9840</h4>
                    </div>
                    <div className="topHeaderContactElement">
                        <div className="topHeaderContactIcon">
                            <img src={mail} alt="Mail Icon" className='icons-mail'/>
                        </div>
                        <h4 className="topHeaderContactText">tgiacademic@gmail.com</h4>
                    </div>
                </div>
                <h4 className="ApplyNow">
                    <Link to="/" className="individualLinks">Apply Now</Link>
                </h4>
            </div>

            <div className="LowerHeader">
                <div className="Links">
                    <Link to="/" className="individualLinks">Home</Link>
                    
                    <div className="dropdown" ref={dropdownRef}>
                        <h4 className="individualLinks" onClick={() => setOpenMenu(prev => !prev)}>Courses</h4>
                        {openMenu && <DropDown />}
                    </div>
                    <Link to="/AboutUs" className="individualLinks">About Us</Link>
                </div>
            </div>
        </div>
    );
}
